export enum CssVariables {
  white = "var(--white)",
  black = "var(--black)",
  frameColor = "var(--frameColor)",
  actionColor = "var(--actionColor)",
  navigationTabBackground = "var(--navigationTabBackground)",
  activeNAvigationTabBackground = "var(--activeNAvigationTabBackground)",
  navigationTabTextColor = "var(--navigationTabTextColor)",
  lightBlue = "var(--lightBlue)",
  mildBlue = "var(--mildBlue)",
  ctaBlue = "var(--ctaBlue)",
  primary = "var(--primary)",
  warning = "var(--warning)",
  opaqueGreen = "var(--opaqueGreen)",
  paperBorderRadius = "var(--paperBorderRadius)",
  buttonBorderRadius = "var(--buttonBorderRadius)",
  commonTextColor = "var(--commonTextColor)",
  error = "var(--error)",
  /**#363636 */
  dark = "var(--dark)",
  placeholderColor = "var(--placeholderColor)",
  grey = "var(--grey)",
  primaryDisabled = "var(--primaryDisabled)",
  tint = "var(--tint)",
  lightTint = "var(--lightTint)",
  green = "var(--green)",
  mildGreen = "var(--mildGreen)",
  secondary = "var(--secondary)",
  red = "var(--red)",
  mildRed = "var(--mildRed)",
  mildYellow = "var(--mildYellow)",
  lightGrey = "var(--lightGrey)",
  chipBackgroundColor = "var(--chipBackgroundColor)",
  gridInputBorder = "var(--gridInputBorder)",
  paperBackground = "var(--paperBackground)",
  bodyTextColor = "var(--bodyTextColor)",
  poolCardBackground = "var(--poolCardBackground)",
  cardBorder = "var(--cardBorder)",
  swapInputbackground = "var(--swapInputbackground)",
  radioWidth = "var(--radioWidth)",
  radioHeight = "var(--radioHeight)",
  navigationDrawerBackground = "var(--navigationDrawerBackground)",
  modalBackground = "var(--modalBackground)",
  modalContentBackground = "var(--modalContentBackground)",
  modalOverlayBackground = "var(--modalOverlayBackground)",
  tableHeadColor = "var(--tableHeadColor)",
  mainBackgroundColor = "var(--mainBackgroundColor)",
}

export enum FontFamilies {
  Poppins = "Poppins !important",
  Inter = "Inter !important",
}
